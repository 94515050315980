import * as React from 'react';
import styled, { css } from 'styled-components';
import cl from 'classnames';

import { colorsV2 } from '../colors-v2';

interface DotDividerProps {
  className?: string;
  type?: 'horizontal' | 'vertical';
  space?: number;
  dotSize?: number;
  background?: string;
}

const Dot = styled.div<DotDividerProps>`
  border-radius: 50%;

  ${(p) => css`
    width: ${p.dotSize}px;
    height: ${p.dotSize}px;
    margin: ${p.type === 'horizontal' ? `0 ${p.space}px` : `${p.space}px 0`};
    background: ${p.background};
  `}
`;

export const DotDivider = (props: DotDividerProps) => {
  const { className, type = 'horizontal', space = 8, dotSize = 2, background = colorsV2.gray100 } = props;

  return (
    <Dot
      className={cl('dot-divider', className)}
      type={type}
      space={space}
      dotSize={dotSize}
      background={background}
    />
  );
};
