import styled from 'styled-components';
import { variant } from 'styled-system';
import { colorsV2 } from '../colors-v2';

export interface LineDividerProps {
  directional?: 'horizontal' | 'vertical';
  size?: number;
  space?: number;
  color?: string;
}

export const LineDivider = styled.div<LineDividerProps>`
  ${(p) =>
    variant({
      prop: 'directional',
      variants: {
        horizontal: {
          borderBottom: `${p.size}px solid ${p.color}`,
          margin: `${p.space}px 0`,
        },
        vertical: {
          borderRight: `${p.size}px solid ${p.color}`,
          margin: `0 ${p.space}px`,
          height: '100%',
        },
      },
    })}
`;

LineDivider.defaultProps = {
  directional: 'horizontal',
  space: 8,
  size: 1,
  color: colorsV2.black10,
};
