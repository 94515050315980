import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import {
  NotLinkedWarningPopover,
} from './NotLinkedWarningPopover';
import { horizontalSpace } from '@doltech/utils/lib/css-style';

const ContainerWithLink = styled.a`
  .title {
    color: ${colorsV2.gray180};

    &:hover {
      cursor: pointer;
      color: ${colorsV2.purple100};
    }
  }
`;

const ContainerWithDisabled = styled.div`
  display: flex;
  ${horizontalSpace(4)};
  cursor: not-allowed;
`;

interface GrammarCardBlogHeaderProps {
  isLackOfUrl?: boolean;
  detailUrl?: string;
  unListed?: boolean;
  ellipsisTitle?: any;
  title?: string;
  customTitle?: React.ReactNode;
}

export const GrammarCardBlogHeader = (props: GrammarCardBlogHeaderProps) => {
  const {
    isLackOfUrl,
    detailUrl,
    unListed,
    ellipsisTitle,
    title,
    customTitle,
  } = props;

  if (unListed) {
    return (
      <ContainerWithDisabled>
        <ResponsiveTypography.Paragraph
          className="title"
          variant="semi-bold/20-28"
          ellipsis={ellipsisTitle}
          color="gray100"
        >
          {customTitle || title}
        </ResponsiveTypography.Paragraph>
        <NotLinkedWarningPopover/>
      </ContainerWithDisabled>
    );
  }

  if (isLackOfUrl) {
    return (
      <ResponsiveTypography.Paragraph
        className="title"
        variant="semi-bold/20-28"
        ellipsis={ellipsisTitle}
        color="gray100"
      >
        {customTitle || title}
      </ResponsiveTypography.Paragraph>
    );
  }

  return (
    <ContainerWithLink
      rel="noreferrer"
      href={detailUrl}
    >
      <ResponsiveTypography.Paragraph
        className="title"
        variant="semi-bold/20-28"
        ellipsis={ellipsisTitle}
      >
        {customTitle || title}
      </ResponsiveTypography.Paragraph>
    </ContainerWithLink>
  );
};
