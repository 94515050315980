import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { horizontalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const Container = styled.div`
  width: max-content;
  background-color: ${colorsV2.purple5};
  border: 1px solid ${colorsV2.purple10};
  border-radius: 20px;
  display: flex;
  align-items: center;

  padding: 2px 8px 2px 2px;
  ${horizontalSpace(4)};

  ${fromScreen(768)} {
    padding: 4px 12px 4px 4px;
    ${horizontalSpace(8)};
  }
`;

const MediaWrapper = styled.div`
  img {
    border-radius: 40px;
    width: 24px;
    height: 24px;
  }

  .empty-state {
    border-radius: 40px;
    width: 24px;
    height: 24px;
    background-color: ${colorsV2.gray20};
  }
`;

interface ChipTopicInBlogProps {
  url?: string;
  text?: string;
}

export const ChipTopicInBlog = (props: ChipTopicInBlogProps) => {
  const { url, text } = props;
  return (
    <Container>
      <MediaWrapper>
        {url ?
          <img src={url} alt={text}/> :
          <div className="empty-state"/>
        }
      </MediaWrapper>
      <ResponsiveTypography.Paragraph variant="medium/14-20" color="purple100">
        {text}
      </ResponsiveTypography.Paragraph>
    </Container>
  );
};
