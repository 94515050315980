import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { flexGap, verticalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { DotDivider } from '@doltech/ui/lib/figma/Divider/DotDivider';
import { ChipTopicInBlog } from './ChipTopicInBlog';
import { GrammarReadingStatus } from '../components/GrammarReadingStatus';
import { ReadDoStatusEnum } from '../../shared/constants';
import { GrammarCardBlogHeader } from '../components/GrammarCardBlogHeader';
import cl from 'classnames';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import {
  GrammarCardBlogHeaderLanding,
} from '../components/GrammarCardBlogHeaderLanding';

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: flex-start;
  column-gap: 12px;

  ${fromScreen(768)} {
    column-gap: 16px;
  }

  ${fromScreen(1024)} {
    column-gap: 24px;
  }

  &.layout-image {
    grid-template-columns: 52px minmax(0, 1fr);

    ${fromScreen(768)} {
      column-gap: 16px;
      grid-template-columns: 92px minmax(0, 1fr);
    }

    ${fromScreen(1024)} {
      column-gap: 24px;
      grid-template-columns: 174px minmax(0, 1fr);
    }
  }
`;

const MainContent = styled.div`
  background-color: ${colorsV2.white100};
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-rows: max-content;
  gap: 12px;
`;

const ElementDateView = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  ${flexGap([4, 4])}
`;

const BodyWrapper = styled.div`
  ${verticalSpace(12)};
`;

const StatusLineWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  ${flexGap([8, 8])};
`;

const ImageWrapper = styled.div`
  img {
    object-fit: cover;
    border-radius: 8px;
    width: 52px;
    aspect-ratio: 1;
  }

  ${fromScreen(768)} {
    img {
      width: 92px;
    }
  }

  ${fromScreen(1024)} {
    img {
      width: 174px;
      aspect-ratio: 4 / 3;
    }
  }

  &.disabled {
    background: ${colorsV2.gray20};
  }
`;

export interface GrammarCardBlogContentProps {
  title?: string;
  subLeftText?: string;
  subRightText?: string;
  description?: string;
  topicUrl?: string;
  topicName?: string;
  status?: ReadDoStatusEnum;
  type?: 'theory' | 'exercise';
  ellipsisTitle?: any;
  isRecommend?: boolean;
  unListed?: boolean;
  isLackOfUrl?: boolean;
  detailUrl?: string;
  imageUrl?: string;
  imageAlt?: string;
  isShowImage?: boolean;
  isLanding?: boolean;
}

export const GrammarCardBlog = (props: GrammarCardBlogContentProps) => {
  const {
    title,
    subLeftText,
    subRightText,
    description,
    topicUrl,
    topicName,
    status,
    type,
    ellipsisTitle,
    isRecommend,
    unListed,
    isLackOfUrl,
    detailUrl,
    imageUrl,
    isShowImage,
    isLanding,
    imageAlt,
  } = props;

  return (
    <Container className={cl('card-home-reason', { 'layout-image': isShowImage })}>
      {isShowImage &&
        <ImageWrapper className={cl({ 'disabled': !imageUrl })}>
          <img src={imageUrl} alt={imageAlt}/>
        </ImageWrapper>
      }
      <MainContent>
        {isLanding ? (
          <GrammarCardBlogHeaderLanding
            title={title}
            ellipsisTitle={ellipsisTitle}
            unListed={unListed}
            isLackOfUrl={isLackOfUrl}
            detailUrl={detailUrl}
          />
        ) : (
          <GrammarCardBlogHeader
            title={title}
            ellipsisTitle={ellipsisTitle}
            unListed={unListed}
            isLackOfUrl={isLackOfUrl}
            detailUrl={detailUrl}
          />
          )}
        <ElementDateView>
          <ResponsiveTypography.Paragraph variant="medium/14-20" color="gray100">
            {subLeftText}
          </ResponsiveTypography.Paragraph>
          <DotDivider background={colorsV2.neutral40} space={0} dotSize={3}/>
          <ResponsiveTypography.Paragraph variant="medium/14-20" color="gray100">
            {subRightText}
          </ResponsiveTypography.Paragraph>
        </ElementDateView>
        <BodyWrapper>
          <ResponsiveTypography.Paragraph
            variant="regular/14-20"
            color="gray160"
            ellipsis={{ rows: 3, tooltip: false }}
          >
            {description}
          </ResponsiveTypography.Paragraph>
          <StatusLineWrapper>
            <ChipTopicInBlog
              url={topicUrl}
              text={topicName}
            />
            <GrammarReadingStatus
              type={type}
              status={status}
              isRecommend={isRecommend}
            />
          </StatusLineWrapper>
        </BodyWrapper>
      </MainContent>
    </Container>
  );
};
