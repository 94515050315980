import * as React from 'react';
import styled, { css } from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { horizontalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { IconSystemV2StatusCheck2 } from '@doltech/icons/SystemV2/IconSystemV2StatusCheck2';
import { IconSystemV2StatusInprogress } from '@doltech/icons/SystemV2/IconSystemV2StatusInprogress';
import { IconSystemV2SpecificAi5 } from '@doltech/icons/SystemV2/IconSystemV2SpecificAi5';
import { ReadDoStatusEnum } from '../../shared/constants';

const generateColorStyles = css`
  &.blue {
    border: 1px solid ${colorsV2.blue20};

    .icon {
      color: ${colorsV2.blue100};
    }

    .text {
      color: ${colorsV2.blue100};
    }
  }

  &.green {
    border: 1px solid ${colorsV2.green20};

    .icon {
      color: ${colorsV2.green100};
    }

    .text {
      color: ${colorsV2.green100};
    }
  }

  &.yellow {
    border: 1px solid ${colorsV2.yellow40};

    .icon {
      color: ${colorsV2.yellow120};
    }

    .text {
      color: ${colorsV2.yellow120};
    }
  }
`;

const Container = styled.div`
  width: max-content;
  padding: 2px 8px 2px 2px;
  border-radius: 20px;
  display: flex;
  ${horizontalSpace(4)};

  ${generateColorStyles}
  ${fromScreen(768)} {
    padding: 4px 10px 4px 4px;
    ${horizontalSpace(8)};
  }
`;

const IconWrapper = styled.div`
  svg {
    width: 24px;
    height: 24px;

    ${fromScreen(768)} {
      width: 20px;
      height: 20px;
    }
  }
`;

interface GrammarReadingStatusProps {
  type: 'theory' | 'exercise';
  status?: ReadDoStatusEnum;
  isRecommend?: boolean;
}

export const GrammarReadingStatus = (props: GrammarReadingStatusProps) => {
  const { type, status, isRecommend } = props;

  const content = React.useMemo(() => {
    switch (status) {
      case 'IN_PROGRESS': {
        return {
          icon: <IconSystemV2StatusInprogress/>,
          text: type === 'theory' ? 'Đang đọc' : 'Đang làm',
          color: 'blue',
        };
      }
      case 'COMPLETED': {
        return {
          icon: <IconSystemV2StatusCheck2/>,
          text: type === 'theory' ? 'Đã đọc' : 'Đã làm',
          color: 'green',
        };
      }
      case 'NOT_STARTED':
      default: {
        if (isRecommend) {
          return {
            icon: <IconSystemV2SpecificAi5/>,
            text: 'Đề xuất',
            color: 'yellow',
          };
        }
        return null;
      }
    }
  }, [status, type, isRecommend]);

  return (
    <Container className={content?.color}>
      <IconWrapper className="icon">{content?.icon}</IconWrapper>
      <ResponsiveTypography.Paragraph className="text" variant="medium/14-20">
        {content?.text}
      </ResponsiveTypography.Paragraph>
    </Container>
  );
};
