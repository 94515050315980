import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.833 3.667a3.256 3.256 0 002.5 2.5 3.256 3.256 0 00-2.5 2.5 3.256 3.256 0 00-2.5-2.5 3.256 3.256 0 002.5-2.5zm-8.273.166a9.196 9.196 0 007.06 7.06 9.196 9.196 0 00-7.06 7.06 9.196 9.196 0 00-7.06-7.06 9.196 9.196 0 007.06-7.06zM21.333 17A4.341 4.341 0 0118 13.666 4.342 4.342 0 0114.667 17 4.342 4.342 0 0118 20.333 4.342 4.342 0 0121.333 17z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export const IconSystemV2SpecificAi5 = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
